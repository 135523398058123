<template>
	<el-card class="announcement-block" v-if="detData.length>0">
		<div class="announcement-title">
			<div>{{$t('i18nn_7d6f758a93cb33b5')}}</div>
			<div>
				<!-- <el-badge :is-dot="detData.length>0" class=""> -->
					<el-button icon="el-icon-message-solid" circle @click="toMoreList()"></el-button>
				<!-- </el-badge> -->
			</div>
		</div>
		<div class="announcement">
			
			<el-carousel height="160px" direction="horizontal" :autoplay="true" :interval="3000"
				:indicator-position="detData.length>1?'outside':'none'" :arrow="detData.length>1?'hover':'never'"
				@change="carouselChange">
				<el-carousel-item v-for="(item,index) in detData" :key="index">
					<div class="announcement-det">
						<h2 class="announcement_h2">{{item.msgText}}</h2>
						<div class="ql-snow">
							<div class="ql-editor" v-html="item.content">
							</div>
						</div>
					</div>
			
				</el-carousel-item>
			</el-carousel>
			
		</div>
	</el-card>
</template>
<script>
	import 'quill/dist/quill.snow.css'
	export default {
		components: {},
		data() {
			return {
				loading_load: false,
				detData: [],
			};
		},
		activated() {
			
		},
		mounted() {
			this.initData();
		},
		methods: {
			initData() {
				this.detData = [];
				//初始化上传控件
				this.getData();
			},
			toDet(event, row) {
				event.stopPropagation();
			},
			toMoreList() {
				// event.stopPropagation();
				this.$router.push({name:"NoticeList"})
			},
			carouselChange(index) {
				console.log('home carouselChange');
				// this.getConTextData(index);
			},
			//请求分页数据
			getData() {
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhNoticeList, {
						"offset": "0",
						"limit": "10",
						"msgType":"20",
						"status":"10"
					})
					.then(({
						data
					}) => {
						console.log(data);
						this.loading_load = false;
						if (200 == data.code && data.rows) {
							this.detData = data.rows;
							if(this.detData.length>0){
							// 	this.getConTextData(0)
							// 
								this.detData.forEach((item,index)=>{
									this.getConTextData(index);
								});
							}
						}
					})
					.catch(error => {
						console.log(error);
						this.loading_load = false;
					});
			},
			//请求
			getConTextData(index) {
				// console.log('getConTextData',index);
				if(!this.detData[index]){
					return;
				}
				if(!!this.detData[index].content){
					return;
				}
				let key = this.detData[index].msgTextKey;
				this.$http
					.get(this.$urlConfig.WhNoticeMoreText + "?keyId=" + key, {})
					.then(({
						data
					}) => {
						console.log(data);
						if (200 == data.code && data.data) {
							let detData = this.detData[index];
							detData.content = data.data;
							this.$set(this.detData, detData, index);
							this.$forceUpdate();
						}
					})
					.catch(error => {
						console.log(error);
					});
			},
		}
	};
</script>

<style scoped lang="less">
	.announcement-block {
		border-color:red;
		max-width: 600px;
	}
	.announcement-title {
		font-size: 18px;
		padding-bottom: 10px;
		display: flex;
		justify-content: space-between;
	}
	.announcement {
		overflow: hidden;
		// max-width:600px;
		.announcement-det {
			height: 160px;
			overflow: auto;
		}
		.announcement_h2 {
			font-size: 20px;
			line-height: 150%;
		}
		// .ql-editor {
		// 	ul,ol {
		// 		margin-left: 20px;
		// 		li {
		// 			list-style: decimal outside none;
		// 			font-size: 16px;
		// 			line-height: 120%;
		// 			padding: 5px 0;
		// 		}
		// 	}
		// 	p {
		// 		font-size: 16px;
		// 		line-height: 120%;
		// 		padding: 5px 0;
		// 	}
		// }
		// .announcement_ul {
		// 	margin-left: 20px;
		// 	.announcement_li {
		// 		list-style: decimal outside none;
		// 		font-size: 16px;
		// 		line-height: 120%;
		// 		padding: 5px 0;
		// 	}
		// }
		// .announcement_p {
		// 	font-size: 16px;
		// 	line-height: 120%;
		// 	padding: 5px 0;
		// }
	}
</style>
