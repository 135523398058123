// 【劳工节放假通知】
// 9.6.2022（周一） 为美国国定假日
// 仓库、卡车、及第三方物流Fedex/UPS等 将会放假一天
// 请客户提前做好相关的安排
// 谢谢！

// 【旺季附加费】
// 8.23.2022 （周二）将发布2022-23年度旺季附加费调整通知。
// 请各位客户注意查收邮件及微信通知。
// 谢谢！
// this.$notify({
// 	title: '【劳工节放假通知】',
// 	type: 'info',
// 	position: 'bottom-right',
// 	duration: 10000,
// 	dangerouslyUseHTMLString: true,
// 	message: `
// 					9.6.2022（周一） 为美国国定假日<br />
// 					仓库、卡车、及第三方物流Fedex/UPS等 将会放假一天<br />
// 					请客户提前做好相关的安排<br />
// 					谢谢！<br />
// 				`
// });
const list = [
	// {
	// 	"title": "【旺季附加费】",
	// 	"message": `
	// 									8.23.2022 （周二）将发布2022-23年度旺季附加费调整通知。<br />
	// 									请各位客户注意查收邮件及微信通知<br />
	// 									谢谢！<br />
	// 								`
	// },
	// {
	// 	"title": "【尾程渠道旺季附加费更新】",
	// 	"message": `
	// 										关于尾程渠道旺季附加费通知已更新，请到<a href="https://docs.qq.com/sheet/DWWJTeE5RTkp4ZlpN?tab=v7qszy" target="_blank">官方报价表</a>查看<br />
	// 										<br />
	// 										<strong>更新内容包括以下：</strong><br />
	// 										渠道【S】<br />
	// 										Peak-AHS <br />
	// 										Peak-Oversize<br />
	// 										Peak-Residential Surcharge<br />
	// 										渠道【V】<br />
	// 										Peak-AHS <br />
	// 										Peak-Oversize<br />
	// 										Peak-Residential Surcharge<br />
	// 									`
	// }
];

export default list
